import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../../commons/components/animated'
import {useMobileFontOverrides} from '../../../../../../../commons/hooks/use-mobile-font-overrides'
import {isMobile, isSite} from '../../../../../../../commons/selectors/environment'
import {useWidgetState} from '../../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {getRibbonData} from '../../../../selectors/events'
import {isItemOpened} from '../../../../selectors/layout'
import {getComponentConfig, isListLayout, isMembersVisible} from '../../../../selectors/settings'
import {FullDateLocation} from '../../../full-date-location'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Image} from '../../common/image'
import {AdditionalInfoToggle} from './additional-info-toggle'
import s from './cards-mobile-item.scss'
import {Date} from './date'
import {Description} from './description'
import {Members} from './members'
import {Share} from './share'
import {Title} from './title'

export const CardsMobileItem = ({event, hovered}: {event: ExtendedEvent; hovered: boolean}) => {
  const {
    allBreakpoints: {isListImageEnabled},
  } = useVisibilityStyles()
  const imageVisible = isListImageEnabled()
  const opened = useWidgetState(state => isItemOpened(state, event.id))
  const mobile = useWidgetState(isMobile)
  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)
  const animates = useWidgetState(isSite)
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  return (
    <>
      {imageVisible ? (
        <div className={s.image}>
          <Image event={event} />
        </div>
      ) : null}
      {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
      <Date event={event} listLayout={listLayout} />
      <Title event={event} />
      <AdditionalInfoToggle opened={opened} active={!mobile && (hovered || opened)} />
      <div className={s.additionalInfoContainer}>
        <Animated disabled={!animates} show={opened} height={calculateContentHeight}>
          <div
            className={classNames(s.additionalInfo, listLayout ? s.listContentText : s.mobileCardsContentText, {
              [s.mobileFontSize]: mobileFontOverridesEnabled,
            })}
          >
            <FullDateLocation event={event} />
            {showMembers ? <Members event={event} /> : null}
            <Description event={event} />
            <Share event={event} />
          </div>
        </Animated>
      </div>
      <RsvpButton
        event={event}
        fullWidth={mobile}
        containerClassName={s.buttonContainer}
        className={mobileFontOverridesEnabled ? s.buttonFont : null}
      />
    </>
  )
}

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
